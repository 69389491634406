export const firebaseConfig = {
  apiKey: 'AIzaSyCc4zy8XqFI0VelHQxTQfVUswZIPu4quBY',
  authDomain: 'jenny-dev-platform.firebaseapp.com',
  databaseURL: 'https://jenny-dev-platform.firebaseio.com',
  projectId: 'jenny-dev-platform',
  storageBucket: 'jenny-dev-platform.appspot.com',
  messagingSenderId: '393441259479',
  appId: '1:393441259479:web:f3d1545925332ffaa0a592',
  measurementId: 'G-Z2FJT4QHP9',
};

export const buildNumberWeb = '140';
