/** istanbul ignore file */

import {
  createNavigationContainerRef,
  DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {Text} from 'native-base';
import React, {useContext, useEffect} from 'react';
import {SessionContext} from '../hooks/SessionContext';
import ChatExercise from '../screens/Chat/ChatExercise';
import Login from '../screens/login/Login';
import {colors} from '../styles/colors';
import AllTools from '../screens/Home/AllTools';
import EvaluationIntro from '../screens/Evaluation/EvaluationIntro';
import Evaluation from '../screens/Evaluation/Evaluation';
import SessionDetailsTherapy from '../screens/Therapy/SessionDetailsTherapy';

/**
 * Componente  MainNavigator  WEB
 */
export const navigationRef = createNavigationContainerRef();
const Stack = createNativeStackNavigator();

let paramAux;

export function getParams(paramsID) {
  // función para traer los params de handleDynamicLink en App
  paramAux = paramsID;
}

// function to Navigate using import {useLinkTo} from '@react-navigation/native';
export function navigateTo(name, params, reset) {
  if (!navigationRef.isReady()) {
    console.log('el navegador no esta listo,navegando a init');
    return;
  }
  switch (name) {
    case 'goBack':
      navigationRef.goBack();
      return;
    case 'pop':
      navigationRef?.pop();
      return;
    case 'Login':
      navigationRef.navigate(name, params);
      return;
    case 'EmployeeLogin':
      if (reset) {
        navigationRef.reset({
          index: 0,
          routes: [{name, params}],
        });
        return;
      }
      navigationRef.navigate(name, params);
      return;
    case 'Home':
      navigationRef.navigate(name, params);
      return;
    case 'Chat':
      navigationRef.navigate(name, params);
      return;
    case 'ChatExercise':
      navigationRef.navigate(name, params);
      return;
    case 'AllTools':
      navigationRef.navigate(name, params);
      return;
    case 'Evaluacion':
      console.log('navegando a evaluacion');
      navigationRef.navigate('Evaluation', params);
      return;
    case 'TherapyDetails':
      navigationRef.navigate('TherapyDetails', params);
      return;
    default:
      // Check if this code works
      if (reset) {
        navigationRef?.reset({
          index: 0,
          routes: [{name, params}],
        });
        return;
      }
      navigationRef.navigate(name, params);
      return;
  }
}

export const ROUTE_NAMES = {
  home: 'Home',
  login: 'Login',
  therapyDetails: 'TherapyDetails',
};

const MainNavigator = ({routeLink, setRouteLink}) => {
  const {session} = useContext(SessionContext);

  useEffect(() => {
    goToDeepLink(routeLink);
  }, [routeLink]);

  const navTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: colors.tertiary5,
    },
  };

  function goToDeepLink(route) {
    let id = paramAux;

    // switch to specific route
    switch (route) {
      case 'therapyDetails':
        if (!session.authenticated) {
          navigationRef.navigate(ROUTE_NAMES.login, {id, goTherapy: true});
        } else {
          navigationRef.navigate(ROUTE_NAMES.therapyDetails, {id});
        }
        break;
      default:
        break;
    }
  }

  function getInitialRoute() {
    if (!session.authenticated) {
      if (navigationRef?.current?.getCurrentRoute()?.params?.registerPrefix) {
        return 'EmployeeLogin';
      }
      return 'Login';
    }
    return 'Home'; //'Home';
  }

  const config = {
    screens: {
      Home: 'home',
      Login: 'login',
      EmployeeLogin: 'ext/:registerPrefix',
      Chat: 'chat',
      AllTools: 'exercises/:id',
      EvaluationIntro: 'evaluationIntroduction/:id',
      Evaluation: 'evaluation/:id',
      TherapyDetails: 'therapyDetails/:id',
    },
  };

  const linking = {
    prefixes: [
      /* your linking prefixes */
    ],
    config,
  };

  return (
    <NavigationContainer
      theme={navTheme}
      ref={navigationRef}
      linking={linking}
      fallback={<Text>Loading...</Text>}
      onReady={() => {
        if (
          (navigationRef.current.getCurrentRoute() &&
            navigationRef.current.getCurrentRoute().name === 'EmployeeLogin') ||
          !session.authenticated
        ) {
          navigationRef.current?.navigate(
            getInitialRoute(),
            navigationRef.current.getCurrentRoute()?.params,
          );
        }
      }}
      independent={true}>
      <Stack.Navigator
        screenOptions={{
          gestureEnabled: false,
          headerHideShadow: true,
          headerShadowVisible: false,
          borderBottomWidth: 0,
          cardStyle: {
            backgroundColor: colors.tertiary5,
            //borderTopColor: 'pink',
            // paddingVertical: 10,
            // marginHorizontal: 1,
          },
          //add hide header
          headerShown: false,
        }}
        initialRouteName={getInitialRoute()}>
        <Stack.Screen name={'Home'} component={ChatExercise} />
        <Stack.Screen name={'Login'} component={Login} />
        <Stack.Screen name={'EmployeeLogin'} component={Login} />
        <Stack.Screen name={'Chat'} component={ChatExercise} />
        <Stack.Screen name={'AllTools'} component={AllTools} />
        <Stack.Screen name={'EvaluationIntro'} component={EvaluationIntro} />
        <Stack.Screen name={'Evaluation'} component={Evaluation} />
        <Stack.Screen
          name={'TherapyDetails'}
          component={SessionDetailsTherapy}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default MainNavigator;
